@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Sen", sans-serif;
}
*::after {
  border: none;
}
svg,
button {
  cursor: pointer;
}
