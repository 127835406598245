
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
nav ul {
  display:flex;
  transition: all  200ms linear;
}
@media (max-width:640px){
#mobile{
  display:flex;

}
#notMobile{
  display:none;
}
}


.dynamic-txts li{

  animation: slide 12s steps(4) infinite;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}

.dynamic-txts li span::after{
  line-height:95px;
  height:105%;
  background:white;
  border-left: 2px solid #FC6D6D;
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}

.recentwork::hover figcaption{
  display:block;
}